import axios, { AxiosError } from 'axios'
import { Either, left, right } from 'fp-ts/Either'

export interface FormData {
  name: string
  lastname: string
  email: string
  phone: string
  date: string
  time: string
  people: string
  message?: string
  privacy_policy: boolean
}

export interface FoodFormSenderBackendConfiguration {
  formURL: string
}

export type FoodFormSenderBackend = (
  data: FormData,
) => Promise<Either<AxiosError, void>>

export const FoodFormSenderBackend =
  ({ formURL }: FoodFormSenderBackendConfiguration): FoodFormSenderBackend =>
  async (data): Promise<Either<AxiosError, void>> => {
    try {
      await axios({
        method: 'POST',
        url: formURL,
        headers: {
          'Content-type': 'application/json',
        },
        data,
      })
      return right(undefined)
    } catch (error) {
      return left(error as any)
    }
  }
