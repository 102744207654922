import '../../theme/datepicker.css'

import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Checkbox } from 'app/components/Common/Checkbox'
import { FormMessages } from 'app/components/Common/FormMessages'
import { Input } from 'app/components/Common/Input'
import { Select } from 'app/components/Common/Select'
import { Spinner } from 'app/components/Common/Spinner'
import { Textarea } from 'app/components/Common/Textarea'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { FoodFormSenderBackend, FormData } from 'app/utils/FoodFormSender'
import { useVocabularyData } from 'app/utils/vocabulary'
import { de, enUS, es, fr, it, pt } from 'date-fns/esm/locale'
import { isLeft } from 'fp-ts/lib/These'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import { useForm } from 'react-hook-form'

registerLocale('en', enUS)
registerLocale('it', it)
registerLocale('es', es)
registerLocale('fr', fr)
registerLocale('de', de)
registerLocale('pt', pt)

const EMAIL_REGEX = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i

export interface Props {
  languageCode: string
  languagePrefix?: string | null
  onFoodFormSendToBackend: FoodFormSenderBackend
}

export const FoodForm = memo(function FoodForm({
  languageCode,
  languagePrefix,
  onFoodFormSendToBackend,
}: Props) {
  /*
   * Datepicker configuration
   */
  const [date, setDate] = useState<any>()
  const [dateValue, setDateValue] = useState('')
  /*
   * Form data
   */
  const [isSendingForm, setIsSendingForm] = useState(false)
  const [displaySendErrorFeedback, setDisplaySendErrorFeedback] =
    useState(false)
  const [displaySendConfirmFeedback, setDisplaySendConfirmFeedback] =
    useState(false)

  const onValidSubmission = useCallback(
    async (data: FormData) => {
      if (isSendingForm) {
        return
      }

      setDisplaySendErrorFeedback(false)
      setDisplaySendConfirmFeedback(false)
      setIsSendingForm(true)
      const sendResult = await onFoodFormSendToBackend(data)
      setIsSendingForm(false)

      if (isLeft(sendResult)) {
        setDisplaySendErrorFeedback(true)
      } else {
        setDisplaySendConfirmFeedback(true)

        typeof window.gtag !== 'undefined' &&
          window.gtag('event', 'Submit', {
            event_category: 'Website',
            event_label: 'Food Form',
          })
      }
    },
    [isSendingForm, onFoodFormSendToBackend],
  )

  const { register, formState, handleSubmit, setValue } = useForm<FormData>({
    mode: 'onBlur',
    shouldFocusError: true,
  })

  const onSubmit = useMemo(
    () => handleSubmit(onValidSubmission),
    [handleSubmit, onValidSubmission],
  )

  /**
   * Handle scrolling to first field with errors
   */

  useEffect(() => {
    if (formState.errors) {
      const firstErrorElement = Object.values(formState.errors).find(
        (fieldError) => fieldError?.ref !== undefined,
      )?.ref as HTMLElement | undefined

      if (firstErrorElement && firstErrorElement.scrollIntoView) {
        firstErrorElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    }

    if (dateValue) {
      setValue('date', dateValue)
    }
  }, [formState.errors, dateValue])

  return (
    <Container id="form--food">
      <Wrapper>
        <FadeInUp>
          <Form
            onSubmit={isSendingForm ? undefined : onSubmit}
            noValidate
            autoComplete={'off'}
          >
            <Spinner
              className={isSendingForm ? undefined : 'hidden'}
              variant="form-invert"
            />

            <Box row wrap space="between">
              <Field>
                <Label>{`${useVocabularyData('name', languageCode)}*`}</Label>
                <Input
                  error={formState.errors.name !== undefined}
                  isDirty={formState.dirtyFields.name}
                  type="text"
                  required={true}
                  {...register('name', {
                    required: true,
                    validate: (name) => name.length > 2,
                  })}
                />
              </Field>
              <Field>
                <Label>{`${useVocabularyData(
                  'lastname',
                  languageCode,
                )}*`}</Label>
                <Input
                  error={formState.errors.lastname !== undefined}
                  isDirty={formState.dirtyFields.lastname}
                  type="text"
                  required={true}
                  {...register('lastname', {
                    required: true,
                    validate: (lastname) => lastname.length > 2,
                  })}
                />
              </Field>
              <Field>
                <Label>{`${useVocabularyData('email', languageCode)}*`}</Label>
                <Input
                  error={formState.errors.email !== undefined}
                  isDirty={formState.dirtyFields.email}
                  type="email"
                  required={true}
                  {...register('email', {
                    required: true,
                    validate: (email) => EMAIL_REGEX.test(email),
                  })}
                />
              </Field>
              <Field>
                <Label>{`${useVocabularyData('phone', languageCode)}*`}</Label>
                <Input
                  error={formState.errors.phone !== undefined}
                  isDirty={formState.dirtyFields.phone}
                  type="text"
                  {...register('phone', {
                    required: true,
                    validate: (phone) => phone.length > 2,
                  })}
                />
              </Field>
              <Field>
                <Label>{`${useVocabularyData('date', languageCode)}*`}</Label>
                <DatePicker
                  className={
                    formState.errors.date !== undefined && !date
                      ? 'error'
                      : undefined
                  }
                  dateFormat="dd/MM/yyyy"
                  disabledKeyboardNavigation={true}
                  locale={languagePrefix || 'it'}
                  {...register('date', {
                    required: true,
                  })}
                  onChange={(date: any) => {
                    if (date) {
                      setDate(date)
                      setDateValue(
                        date.toLocaleDateString('it-IT', {
                          day: 'numeric',
                          month: '2-digit',
                          year: 'numeric',
                        }),
                      )
                    }
                  }}
                  onFocus={(e) => (e.target.readOnly = true)}
                  // placeholderText={`${useVocabularyData(
                  //   'date',
                  //   languageCode,
                  // )}*`}
                  minDate={new Date()}
                  required={true}
                  selected={date}
                  withPortal={
                    typeof window !== 'undefined' && window.innerWidth > 767
                      ? false
                      : true
                  }
                />
              </Field>
              <Field className="small-field">
                <Label>{`${useVocabularyData('time', languageCode)}*`}</Label>
                <Select
                  error={formState.errors.time !== undefined}
                  isDirty={formState.dirtyFields.time}
                  required={true}
                  options={[
                    { value: '', hidden: true },
                    { value: '19.30' },
                    { value: '20.00' },
                    { value: '20.30' },
                    { value: '21.00' },
                    { value: '21.30' },
                    { value: '22.00' },
                    { value: '22.30' },
                  ]}
                  {...register('time', {
                    required: true,
                  })}
                />
              </Field>
              <Field className="small-field">
                <Label>{`${useVocabularyData('people', languageCode)}*`}</Label>
                <Input
                  error={formState.errors.people !== undefined}
                  isDirty={formState.dirtyFields.people}
                  type="text"
                  {...register('people', {
                    required: true,
                    validate: (people) => people.length > 0,
                  })}
                />
              </Field>
              <Field className="large-field">
                <Label>{useVocabularyData('message', languageCode)}</Label>
                <Textarea {...register('message')} />
              </Field>
              <Checkboxes>
                <Checkbox
                  error={formState.errors.privacy_policy !== undefined}
                  label={useVocabularyData('form-policy-text', languageCode)}
                  required={true}
                  {...register('privacy_policy', {
                    required: true,
                  })}
                />
              </Checkboxes>
              <Input
                name="submit"
                type="submit"
                value={useVocabularyData('send-request', languageCode)}
                variant="submit"
              />
            </Box>

            {displaySendErrorFeedback ? (
              <FormMessages
                languageCode={languageCode}
                text={useVocabularyData('form-error-message', languageCode)}
                title={useVocabularyData(
                  'form-error-message-title',
                  languageCode,
                )}
                type="error"
              />
            ) : null}

            {displaySendConfirmFeedback ? (
              <FormMessages
                languageCode={languageCode}
                text={useVocabularyData(
                  'form-confirmation-message',
                  languageCode,
                )}
                title={useVocabularyData(
                  'form-confirmation-message-title',
                  languageCode,
                )}
              />
            ) : null}
          </Form>
        </FadeInUp>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: -5vw 10vw 15vw;
  padding-top: 5vw;

  @media (max-width: 991px) {
    margin: -30px 0px 60px 0px;
    padding-top: 40px;
  }
`

const Wrapper = styled.div`
  margin: 0 auto;

  .react-datepicker__aria-live {
    display: none !important;
  }

  .react-datepicker {
    z-index: 2;
    left: 0;
    margin-top: 0 !important;
    background-color: ${({ theme }) => theme.colors.variants.neutralLight3};
  }

  .react-datepicker__current-month {
    font-family: ${({ theme }) => theme.fontFamily.heading};
  }

  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day:hover,
  .react-datepicker__day--selected {
    background-color: ${({ theme }) => theme.colors.variants.neutralLight3};
    color: ${({ theme }) => theme.colors.variants.neutralDark1};
  }

  .react-datepicker__day:focus {
    background-color: ${({ theme }) =>
      theme.colors.variants.neutralLight3} !important;
    color: ${({ theme }) => theme.colors.variants.neutralDark1} !important;
  }

  .react-datepicker__day--today {
    &:after {
      background: ${({ theme }) => theme.colors.variants.neutralLight4};
    }
  }

  .react-datepicker__day--disabled {
    color: ${({ theme }) => theme.colors.variants.neutralLight4} !important;
    opacity: 0.3;
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    height: 100%;
    position: relative;
    bottom: auto;
    left: auto;
    input {
      width: 100%;
      height: 3rem;
      padding: 0 1.25rem;
      background: url('./icon-calendar.svg') no-repeat calc(100% - 20px) center
        ${({ theme }) => theme.colors.variants.neutralLight3};
      border-radius: 0.375rem;
      color: ${({ theme }) => theme.colors.variants.neutralDark2};
      font-family: ${({ theme }) => theme.fontFamily.paragraph};
      font-weight: 300;
      font-size: 0.8125rem;
      line-height: 1.625rem;

      &::-webkit-input-placeholder,
      &::-moz-placeholder,
      &::-ms-input-placeholder,
      &::placeholder {
        opacity: 0.4;
      }

      @media (max-width: 1439px) {
        font-size: 16px;
      }

      &::-webkit-input-placeholder {
        color: ${({ theme }) => theme.colors.variants.neutralDark3};
        opacity: 1;
      }
      &::-moz-placeholder {
        color: ${({ theme }) => theme.colors.variants.neutralDark3};
        opacity: 1;
      }
      &::-ms-input-placeholder {
        color: ${({ theme }) => theme.colors.variants.neutralDark3};
        opacity: 1;
      }
      &::placeholder {
        color: ${({ theme }) => theme.colors.variants.neutralDark3};
        opacity: 1;
      }

      &.error {
        border-bottom: 0.0625rem solid red;
      }
    }
  }

  @media (max-width: 991px) {
    padding: 30px;

    .react-datepicker__tab-loop {
      top: 38px;
      left: 0;
    }
    .react-datepicker__day-name,
    .react-datepicker__day {
      width: 8.7vw;
    }
  }
`

const Form = styled.form`
  position: relative;
`

const Box = styled(FlexBox)``

const Field = styled.div`
  width: calc(50% - 1.875rem);
  margin-top: 2.25rem;
  text-align: left;

  @media (max-width: 991px) {
    width: 100%;
    margin-top: 20px;
  }

  &.small-field {
    width: calc(25% - 2.8125rem);

    @media (max-width: 768px) {
      width: calc(50% - 1.875rem);
    }

    @media (max-width: 480px) {
      width: 100%;
    }
  }

  &.large-field {
    width: 100%;
  }
`

const Label = styled.label`
  display: block;
  margin-bottom: 0.75rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 500;
  font-size: 0.625rem;
  letter-spacing: 0.2em;
  text-transform: uppercase;
`

const Checkboxes = styled.div`
  margin-top: 2.25rem;
`
